/*
 * This file is part of the CloserPHP package.
 * For the full copyright and license information, please view the LICENSE.php
 * file that was distributed with this source code.
 * Info:andread.dev@gmail.com
 */

import $ from "jquery";

var locale = {};
var messages = {};
var pluralExpressions = {};
var __slice = Array.prototype.slice;

function pluralFormsIndex(pluralForms, count) {
    if (!pluralForms) {
        return false;
    }

    if (hasKeyPath(pluralExpressions, [pluralForms])) {
        var fn = pluralExpressions[pluralForms];
        return (fn) ? fn(count) : 0;
    }

    var expression = false;
    var nplurals = false;
    var matches = pluralForms.match(/^\s*nplurals\s*=\s*(\d+)\s*;\s+plural\s*=\s*(.+)$/);


    if (matches) {
        expression = matches[2];
        nplurals = parseInt(matches[1]);
    }

    if (!expression) {
        console.log("Unable to parse plural form for: " + pluralForms);
        return pluralExpressions[pluralForms] = false;
    }

    expression = expression.replace(/[^a-zA-Z0-9_:;\(\)\?\|\&=!<>+*\%-]/, "");
    expression = expression.trim().replace(/;+$/g, '');

    var res = '';
    var depth = 0;

    for (var i = 0; i < expression.length; ++i) {
        var char = expression.charAt(i);

        switch (char) {
            case '?':
                res += ' ? (';
                depth++;
                break;
            case ':':
                res += ') : (';
                break;
            case ';':
                res += ')'.repeat(depth) + ';';
                depth = 0;
                break;
            case 'n':
                res += 'n';
                break;
            default:
                res += char;
        }
    }

    res = res.trim().replace(/;+$/g, '');

    var fn = false;
    try {
        fn = new Function("return function (n) { var res=" + res + "; return (typeof res === 'boolean')?((res)?1:0):(parseInt(res));};")();
    } catch (e) {

    }

    if (!fn || typeof fn !== "function") {
        console.log("Unable to create plural function for: " + pluralForms);
        return pluralExpressions[pluralForms] = false;
    }

    try {
        var index = fn(count);
        pluralExpressions[pluralForms] = fn;
        return index;
    } catch (e) {
        return pluralExpressions[pluralForms] = false;
    }
}

function gettext(string, domain) {
    string = string.replace("\r\n", "\n");
    domain = domain || "default";

    if (hasKeyPath(messages, [domain, string])) {
        var trans = messages[domain][string];
        if (typeof trans === 'string') {
            return trans;
        }
        console.log("Bad plural form for singular: " + string + " in " + domain + " domain");
    }

    return string;
}

function ngettext(singular, plural, count, domain) {
    singular = singular.replace("\r\n", "\n");
    plural = plural.replace("\r\n", "\n");
    domain = domain || "default";

    if (isNaN(parseFloat(count))) {
        console.log("Invalid Plural count for singular: " + singular + " in " + domain + " domain");
        count = 0;
    }

    if (hasKeyPath(messages, [domain, singular])) {
        var trans = messages[domain][singular];

        if (trans instanceof Array && trans.length > 0) {
            var index = false;

            if (hasKeyPath(messages, [domain, "%cp-plural"])) {
                index = pluralFormsIndex(messages[domain]["%cp-plural"], count);
            }

            if (index === false) {
                index = 0;
            }

            if (!trans[index]) {
                console.log("Invalid index: " + index + " for plural array for singular: " + singular + " in " + domain + " domain");
                return trans[0];
            }

            return trans[index];
        }

        console.log("Invalid plural array for singular: " + singular + " in " + domain + " domain");
        if (typeof trans === 'string') {
            return trans;
        }
    }

    return (count !== 1) ? singular : plural;
}

function pgettext(context, string, domain) {
    var key = context + "\u0004" + string;
    var trans = gettext(key, domain);
    return (trans.indexOf("\u0004") > 0) ? string : trans;
}

function npgettext(context, singular, plural, count, domain) {
    var key = context + "\u0004" + singular;
    var trans = ngettext(key, plural, count, domain);
    return (trans.indexOf("\u0004") > 0) ? singular : trans;
}

function printf(str, args) {
    args = $.isArray(args) ? args : __slice.call(arguments, 1);
    return str.replace(/([^%]|^)%(?:(\d+)\$)?s/g, function (p0, p, position) {
        if (position) {
            return p + args[parseInt(position) - 1];
        }
        return p + args.shift();
    }).replace(/%%s/g, '%s');
}

function hasKeyPath(array, keyPath) {
    var l = keyPath.length;
    var ref = array;

    for (var i = 0; i < l; i++) {
        if (ref.hasOwnProperty(keyPath[i])) {
            ref = ref[keyPath[i]];
        } else {
            return false;
        }
    }

    return true;
}

/* Load predefined ----------------------*/

if (window._cp_Translations) {
    messages = window._cp_Translations;
}

if (window._cp_Locale) {
    locale = window._cp_Locale;
}

/* Expose ----------------------*/

export function loadTranslations(msg, domain) {
    domain = domain || "default";
    if (!messages.hasOwnProperty(domain)) {
        messages[domain] = {};
    }
    $.extend(messages[domain], msg);
}

export function clearTranslations() {
    messages = {};
}

export function __t(string, domain) {
    var result = gettext(string, domain);
    if (arguments.length > 2) {
        var args = __slice.call(arguments, 2);
        result = printf(result, args);
    }
    return result;
}

export function __tn(singular, plural, count, domain) {
    var result = ngettext(singular, plural, count, domain);
    if (arguments.length > 4) {
        var args = __slice.call(arguments, 4);
        result = printf(result, args);
    }
    return result;
}

export function __tp(context, string, domain) {
    var result = pgettext(context, string, domain);
    if (arguments.length > 3) {
        var args = __slice.call(arguments, 3);
        result = printf(result, args);
    }
    return result;
}

export function __tnp(context, singular, plural, count, domain) {
    var result = npgettext(context, singular, plural, count, domain);
    if (arguments.length > 5) {
        var args = __slice.call(arguments, 5);
        result = printf(result, args);
    }
    return result;
}

export function getLocale(path, vice) {
    var paths = path.split(".");
    var dataRef = locale;
    var missing = false;

    for (var i = 0; i < paths.length; i++) {
        if (typeof dataRef === 'object' && dataRef !== null && dataRef.hasOwnProperty(paths[i])) {
            dataRef = dataRef[paths[i]];
        } else {
            missing = true;
            break;
        }
    }

    if (missing) {
        if (typeof vice === 'undefined') {
            throw new Error("Locale config param " + path + " not defined");
        }
        return vice;
    }

    return dataRef;
}
